import React, { useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import RLC from './RLC'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import packageJson from '../../../frontend/package.json'
const { config } = packageJson

const Form1 = () => {
  const [name, setname] = useState('')
  const [design, setdesign] = useState('')
  const [depart, setdepart] = useState('')
  const [ywe, setywe] = useState('')
  const [tywe, setTywe] = useState('')
  const [hq, sethq] = useState('')

  const postData = async (e) => {
    e.preventDefault()
    alert('done')
    try {
      await axios.get(`http://localhost:8000/addinfo`, { data1: 'dol' })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>
              SUCCESSOR COMPETENCY ENHANCEMENT AND DEVELOPMENT (SUCCEED)
              PROGRAMME HARWOOD TIMBER SDN BHD
            </h2>
            <h4>COMPETENCY GAP ANALYSIS</h4>
            <h6>
              General Instruction: Please evaluate whether the following
              competencies are important/relevant to manager and determine the
              level of competencies that are required for manager, and what is
              your current level of competency by using the following scale:
            </h6>
          </Col>
          <Row>
            <Col>
              <Table striped bordered hover responsive size="sm" variant="dark">
                <thead>
                  <tr>
                    <th colSpan={2}>LEVEL OF IMPORTANT (LOI) SCALE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Level</td>
                    <td>Description</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>
                      This competency is not relevant or important to the job
                      position
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      This competency is quite important/relevant to the job
                      position
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      This competency is important/relevant to the job, but not
                      critical
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      This competency is very important/relevant and critical to
                      the job position
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>This competency is compulsory to the job position</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover responsive size="sm" variant="dark">
                <thead>
                  <tr>
                    <th colSpan={2}>LEVEL OF COMPETENCY (LOC) SCALE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Level</td>
                    <td>Description</td>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>Don’t have any knowledge related to the competency</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Able to state/aware of the competency</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      Able to explain the meaning and how to apply/use the
                      competency in the job
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Able to actually apply, practice, perform or use the
                      competency effectively on the job
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Able to coach, teach or evaluate others on how to apply or
                      perform the competency
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      Able to create, develop, integrate, re-engineer, formulate
                      new models, process, ideas
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover responsive size="sm" variant="dark">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => setdesign(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <td>Department</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => setdepart(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <td>Years of Working Experience in Harwood Timber</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => setywe(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <td>Total Years of Working Experience</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => setTywe(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <td>Highest Qualification</td>
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={(e) => sethq(e.target.value)}
                    />
                  </tr>
                  <tr>
                    <Button variant="outline-primary" onClick={postData}>
                      Save
                    </Button>{' '}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <RLC />
        </Row>
      </Container>
    </>
  )
}

export default Form1
